<template>
    <v-dialog v-model="dialog" persistent max-width="600px" >

      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" rounded>
          Add product
          <v-icon right dark>mdi-plus</v-icon>
        </v-btn>
      </template>

      <template>
        <v-card :disabled="uploading" :loading="uploading">
          <v-card-title>
            <span class="text-h5">{{editProduct ? 'Edit' : 'Add'}} product</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-img :src="previewSrc"></v-img>
                </v-col>
                <v-col cols="8">
                <div>{{product.id}}</div> 
                    <v-file-input accept="image/*"    v-model="fileName" label="Product image" @change="onFileChange"/>
                    <v-text-field label="Title"       v-model="product.title"></v-text-field>
                    <v-text-field label="Price"       v-model="product.price"></v-text-field>
                    <!-- v-text-field label="Product url" v-model="product.productUrl"></v-text-field -->
                    <v-text-field label="Promocode"   v-model="product.promocode"></v-text-field>
                  </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancel" >
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="save" >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>

      </template>
    </v-dialog>
</template>

<script>

  import { getAuth } from 'firebase/auth';
  import { getStorage, getDownloadURL, ref, uploadBytes } from "firebase/storage";
  import { doc, addDoc, setDoc, collection, serverTimestamp } from 'firebase/firestore';
  import { db } from "../firebase"
  
  import placeholderImg from "../assets/image-placeholder.jpg";
  import axios from "axios";
  const s3UploadEndpoint = "https://wwknl5o4cl.execute-api.us-east-1.amazonaws.com/default/getPresignedURL";
  const s3Bucket = "https://streamfuel.s3.amazonaws.com";

  export default {
    name: 'NewProductDialog',

    props: [ "editProduct" ],
    
    data: () => ({
      dialog: false,
      uploading: false,
      previewSrc: placeholderImg,
      resizedImg: null,
      previewImg: null,
      imageFile: null,
      fileName: null,
      product: {
        id: null,
        productUrl: "",
        imageUrl: "",
        previewUrl: "",
        imagePath: "",
        price: "",
        promocode: "",
        title: "",
        enabled: true,
      } 
    }),

    methods: {

      // Сохраняем продукт
      async save() {
        this.uploading = true;
        if(this.imageChanged) await this.uploadImageS3();
        this.putProduct();
      },

      uploadImage() {
        console.log("Upload Image");
        const user = getAuth().currentUser;
        const name = this.imageFile.name;
        const ext  = name.substring(name.lastIndexOf('.')+1);
        const rnd  = Math.random().toString(26).substring(2);
        this.product.imagePath = `images/${user.uid}/${Date.now()}.${rnd}.${ext}`;
      
        const storage = getStorage();
        const storageRef = ref(storage, this.product.imagePath);
        
        return uploadBytes(storageRef, this.resizedImg)
        .then(snp => getDownloadURL(snp.ref))
        .then(url => this.product.imageUrl = url)
        .catch((err)=>console.error(err))
      },

      uploadImageS3() {

        return Promise.all([
          this.uploadImageToS3(this.resizedImg), 
          this.uploadImageToS3(this.previewImg)
        ]).then(([imageUrl, previewUrl])=>{
          this.product.imageUrl = imageUrl;
          this.product.previewUrl = previewUrl;
        })
        .then( () => this.uploading = false )
        .catch( err => console.error(err))
      },
      uploadImageToS3(img) {
        const user = getAuth().currentUser;
        if(!user) return;
        const name = this.imageFile.name;
        const ext  = name.substring(name.lastIndexOf('.')+1);
        const rnd  = Math.random().toString(26).substring(2);
        // Запрашиваем временный урл на аплоад
        return axios.post(s3UploadEndpoint, {
          "filename":`${user.uid}.${rnd}.${ext}`, 
          "content-type": this.imageFile.type})
        .then( res => res.data )
        // аплоадим по полученному урлу
        .then( path =>{
          return axios({
            url: path.uploadURL,
            method: "PUT",
            headers: { 
              "Content-Type": this.imageFile.type,
              "Content-Size": img.size,
            },
            data: img,
          })
          .then(()=>`${s3Bucket}/${path.Key}`)
        })
      }, 
      putProduct() {
        console.log("Put product")
        if(this.product.id!==null) this.saveProduct();
        else this.addProduct();
      },

      addProduct() {
        console.log("Add product")
        const user = getAuth().currentUser;
        // console.log(this.product);
        let created = serverTimestamp();
        let product = {...this.product};
        delete product.id;
        addDoc(collection(db,`users/${user.uid}/products`), {...product, created})
        .catch((err)=>console.error(err))
        .finally(()=>this.clear())
      },

      // сохраняем уже существующий продукт
      // {id, ...rest} => id, {...rest}
      saveProduct() {
        console.log("Save product");
        const user = getAuth().currentUser;
        // console.log(this.product);
        let updated = serverTimestamp();
        // Деструктурируем на id & rest
        let {id, ...product} = this.product;
        setDoc(doc(db,`users/${user.uid}/products/${id}`), {...product, updated})
        .catch((err)=>console.error(err))
        .finally(()=>this.clear())
      },

      cancel() {
        this.clear();
      },

      clear() {
        for(let n of ["productUrl", "imageUrl", "imagePath", "price", "title"]) this.product[n] = "";
        this.product.id  = null;
        this.imageChanged = false;
        this.uploading = false;
        this.fileName = null;
        this.dialog = false;
        this.previewSrc = placeholderImg;
        this.imageFile = null;
        this.$emit("cancel");
      },

      onFileChange(file) {
        this.imageChanged = true;
        this.imageFile = file;
        // 0this.previewImg = URL.createObjectURL(file);
        this.resizeImage(file);
      },

      resizeImage(file) {
        let reader = new FileReader();
        reader.onload = e => {
            let img = document.createElement("img");
            img.onload = () => {
                let cvs = document.createElement("canvas");
                cvs.width = cvs.height = 800;
                let ctx = cvs.getContext("2d");
                // fill. use min for fit
                var s = Math.min(cvs.width / img.width, cvs.height / img.height);
                var x = (cvs.width  / 2) - (img.width  / 2) * s;
                var y = (cvs.height / 2) - (img.height / 2) * s;
                // fill canvas with pixel from corner
                ctx.drawImage(img, 
                  0, 0, 1, 1, 
                  0, 0, cvs.width, cvs.height);
                // fit image to canvas
                ctx.drawImage(img, x, y, img.width * s, img.height * s);
                
                // generate preview
                let cvs_p = document.createElement("canvas");
                cvs_p.width = cvs_p.height = 50;
                let ctx_p = cvs_p.getContext("2d");
                ctx_p.drawImage(cvs, 0, 0, 800, 800, 0, 0, 50, 50);

                this.previewSrc = cvs.toDataURL(file.type);
                cvs.toBlob(blob=>this.resizedImg=blob, file.type);
                cvs_p.toBlob(blob=>this.previewImg=blob, file.type);
            }
            img.src = e.target.result;
        }
        reader.readAsDataURL(file);
      },
    },

    watch: {

      editProduct: {
        immediate: true,
        handler(product) {
          if(product) {
            this.product = {...product};
            this.previewSrc = product.imageUrl;
            this.dialog = true;
          }
        }
      }

    }
  }
</script>
