<template>
  <v-container fluid grid-list-md>
    <v-row class="pa-4">
      <div class="text-h5">Product list</div>
      <v-spacer></v-spacer>
      <NewProductDialog 
        :editProduct="editProduct" 
        @cancel="editProduct=null"/>
    </v-row>
    <v-layout row wrap>       
      <ProductCard 
        v-for="p in products" 
        :key="p.id" 
        :product="p" 
        @delete="deleteProduct(p)"
        @edit="editProduct=p"
        @enable="enableProduct(p)">
      </ProductCard>
    </v-layout>
  </v-container>
</template>

<script>
  
  import ProductCard from "./ProductCard"
  import NewProductDialog from "./NewProductDialog"
  
  import { collection, onSnapshot, deleteDoc, updateDoc, doc, query, orderBy } from 'firebase/firestore';
  // import { getStorage, ref } from "firebase/storage";
  import { getAuth } from 'firebase/auth';

  import { db } from "../firebase"
  
  let user = null;
  export default {
    name: 'ProductList',
    components: {ProductCard, NewProductDialog},
    data: () => ({
      editProduct: null,
      products: [],
    }),

    mounted() {
      // console.log("mount")
      user = getAuth().currentUser;
      if(user) {
        let order = orderBy("created", "desc");
        let product = query(collection(db, `users/${user.uid}/products`), order);
        const close = onSnapshot(product, product_snp => {
          this.products = product_snp.docs.map(doc => ({id: doc.id, ...doc.data()}));
        });
        this.$once("hook:beforeDestroy", ()=>{
          close();
        });
      }
    },

    methods: {
      deleteProduct(product) {
        if(!user || !product) return;
        console.log("Delete product", product);
        // Удаляем продукт
        deleteDoc(doc(db,`users/${user.uid}/products/${product.id}`));
        // Удаление изображения
        // const storage = getStorage();
        // const storageRef = ref(storage, product.imagePath);
        // console.log("Delete photo", product.imagePath);
        // deleteObject(storageRef)
        // .catch((err)=>console.error(err))
        // .finally(()=>deleteDoc(doc(db,`users/${user.uid}/products/${product.id}`)))
      },
      enableProduct(product) {
        if(!user || !product) return;
        let {enabled} = product;
        updateDoc(doc(db,`users/${user.uid}/products/${product.id}`), {enabled})
      }

    }
  }
</script>
