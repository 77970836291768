<template>
  <v-container fluid>
    <v-row>
      <v-col xs="12" sm="6" md="4" lg="3">
        <ProductPlayer />
      </v-col>
      <v-col xs="12" sm="6" md="8" lg="9">
        <ProductList />
      </v-col>
    </v-row>
    <Feedback />
  </v-container>
</template>

<script>

import ProductList from '../components/ProductList'
import ProductPlayer from "../components/ProductPlayer"
import Feedback from "../components/Feedback"
export default {
  name: 'Home',

  components: {
    ProductList, ProductPlayer, Feedback
  },

  data: () => ({
    //
  }),
};
</script>