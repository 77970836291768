<template>
  <v-container>
    <div class="text-h5 pb-4">Ad preview</div>
    <v-card
      :disabled="cardDisabled"
      :loading="loading"
      class="mx-auto">
      <v-img
        :src="playerBackground"
        :aspect-ratio="4/3"
      >
        <!--LottieAnimation :json="playerJson" ></LottieAnimation-->
        <iframe ref="iframe" :srcdoc="playerHtml"></iframe>
        <v-btn 
          @click="fullscreenEnable=true"
          icon
          absolute
          top right
          dark>
          <v-icon>mdi-fullscreen</v-icon></v-btn>
      </v-img>
      
      <v-card-text class="pb-0">
        <v-combobox
          v-model="template"
          :items="templates"
          label="Template"
          @change="updateTemplate($event.value)"
          hideDetails
          solo
          dense
        ></v-combobox>
 
        <v-text-field 
          v-model="playerUrl"
          :disabled="!playerUrl"
          dense
          label="Overlay url"
          class="pt-4 pointer"
          readonly
          @click="openUrl"
          append-icon="mdi-link"
          @click:append="openUrl"
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copyUrl"
        />

      </v-card-text>
      
      <v-card-actions>
        <v-btn 
          :loading="uploading" 
          @click="uploadHtml"
          color="primary">
          Save changes
          <v-icon right>mdi-cloud-upload</v-icon>
        </v-btn>
        <v-btn 
          :disabled="!playerUrl" 
          @click="copyUrl"
          color="primary"
          class="ml-4">
          Copy url
          <v-icon right>mdi-content-copy</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon @click="settings = !settings" > <v-icon>{{ settings ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="settings">
          <v-divider></v-divider>
          <v-card-text>
            
            <div class="text-subtitle-1">Seconds between products</div>
            <v-slider
              v-model="timeout1"
              min="0" max="10"
              thumb-label
              hideDetails
            ></v-slider>
            
            <div class="text-subtitle-1">Seconds between ads</div>
            <v-slider
              v-model="timeout2"
              min="0" max="3600"
              thumb-label
              hideDetails
            ></v-slider>
            <v-btn class="mt-4" @click="updateTemplate">Apply</v-btn>
          </v-card-text>
        </div>
      </v-expand-transition>

    </v-card>
    
    <ProductPlayerFullscreen 
      :enable="fullscreenEnable" 
      @close="fullscreenEnable=false"
      :playerHtml="playerHtml" 
      :playerBackground="playerBackground"
    />    
    
    <v-snackbar v-model="copyNotification" timeout="3000" outlined color="primary">
      <v-icon color="primary">mdi-content-copy</v-icon>
      Overlay URL copied to clipboard
    </v-snackbar>

  </v-container>
</template>

<script>
  
  // import LottieAnimation from "./LottieAnimation"; // import lottie-vuejs
  import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
  // import { getStorage, getDownloadURL, ref, uploadBytes } from "firebase/storage";
  import { getAuth } from 'firebase/auth';
  import { db } from "../firebase";
  import ProductPlayerFullscreen from "./ProductPlayerFullscreen"
  import axios from "axios";
  import playerBackground  from "../assets/player-background-blured.jpg";
  
  const s3UploadEndpoint = "https://wwknl5o4cl.execute-api.us-east-1.amazonaws.com/default/getPresignedURL";
  const s3Bucket = "https://streamfuel.s3.amazonaws.com";
  let user;

  export default {
    name: 'ProductPlayer',

    components: {
     // LottieAnimation
     ProductPlayerFullscreen
    },

    data: ()=>({
      loading: false,
      cardDisabled: true,
      fullscreenEnable: false,
      playerHtml: "",
      playerPath: "",
      playerUrl: "",
      playerBackground, 
      uploading: false,
      settings: false,
      copyNotification: false,
      timeout1: 1,
      timeout2: 5,
      adDuration: 5,
      downloadHref: "",
      productSnapshot: null,
      template: {text: "template 1", value:"001.03"},
      templates: [
        // {text: "template 1", value:"001.02"},
        // {text: "template 2", value:"002.01"},
        {text: "template 1", value:"001.03"},
        {text: "template 2", value:"002.02"},
        {text: "template 3", value:"003.01"},
      ],
      templateJson: null,
      playerJson: null,
      product:[]
    }),

    async mounted() {
      user = getAuth().currentUser;
      if(user) {
        // Подпирываемся на обновление коллекции
        let order = orderBy("created", "desc");
        let product = query(collection(db, `users/${user.uid}/products`), order);
        const close = onSnapshot(product, (m)=>{this.productSnapshot=m; this.updateTemplate()});
        this.$once("hook:beforeDestroy", ()=>close());
      }
    },
    methods: {
      loadTemplate() {
        let tid = this.template.value;
        let url = `${s3Bucket}/templates/${tid}`;
        let html = `${url}/index.html?r=${Math.random()}`;
        let json = `${url}/template.json?r=${Math.random()}`;
        return Promise.all([
          axios.get(html).then(res => res.data),
          axios.get(json).then(res => res.data)
        ])
      },

      // Генериурем html плеера
      async updateTemplate() {
          this.loading=true;
          let [html, json] = await this.loadTemplate();
          // let iframe = this.$refs["iframe"]
          let data = this.productSnapshot.docs.map(doc => ({...doc.data()}));
          data = data.filter((d)=>d.enabled);
          if(!data.length) {
            this.cardDisabled=true;
            this.loading = false;
            this.downloadHref = "";
            this.playerHtml = "";
            return;
          }
          html = html.replace("$DATA", JSON.stringify(data));
          html = html.replace("$JSON", JSON.stringify(json));
          html = html.replace("$TIMEOUT1", this.timeout1);
          html = html.replace("$TIMEOUT2", this.timeout2);
          
          this.playerHtml = html;
          this.downloadHref = 'data:text/html;charset=utf-8,' + encodeURIComponent(html);
          this.loading=false;
          this.cardDisabled = false;
          // Выгружаем HTML в облако
          // return this.uploadHtml();
      },
      uploadHtml() {
        if(!user) return;
        this.uploading = true;
        this.playerUrl = "";
        return axios
        .post(s3UploadEndpoint, {
          "filename":`${user.uid}.html`, 
          "content-type": "text/html"})
        .then( res => res.data )
        .then( path =>(
          this.playerUrl = `https://streamfuel.s3.amazonaws.com/${path.Key}`,
          axios({
            url: path.uploadURL,
            method: "PUT",
            headers: { "Content-Type":  "text/html" },
            data: Buffer.from(this.playerHtml),
          })
        ))
        .then( () => {
          this.uploading = false;
         } )
        .catch( err => console.error(err) )
      },
      openUrl() { window.open(this.playerUrl, "_blank"); },
      copyUrl() { navigator.clipboard.writeText(this.playerUrl); this.copyNotification = true; },
      // Problem with storage hosting
      // uploadHtml() {

      //   console.log("Upload HTML");

      //   this.uploadHtml = true;
      //   const user = getAuth().currentUser;
      //   // const rnd  = Math.random().toString(26).substring(2);
      //   // this.playerPath = `ads/${user.uid}/${Date.now()}.${rnd}.html`;
      //   this.playerPath = `ads/${user.uid}.html`;
      
      //   const storage = getStorage();
      //   const storageRef = ref(storage, this.playerPath);
        
      //   return uploadBytes(storageRef, this.playerHtml)
      //   .then(snp => getDownloadURL(snp.ref))
      //   .then(url => this.playerUrl = url)
      //   .catch((err)=>console.error(err))
      //   .finally(() => this.uploadHtml = false);
      // }
    }
  }
</script>
<style scopped>
  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }

  .pointer input {
    cursor: pointer !important;
  }

</style>