<template>

<v-flex xs12 sm12 md6 lg3>

  <v-card outlined :elevation="product.enabled ? 4 : 0">
    <v-img
      :lazy-src="product.previewUrl"
      :src="product.imageUrl"
      class="white--text align-end pb-4"
      :gradient="product.enabled ? 'to bottom, rgba(0,0,0,.0), rgba(0,0,0,.0), rgba(0,0,0,.5)' : 'to bottom, rgba(200,200,200,.75), rgba(200,200,200,.75), rgba(200,200,200,.75)'"
      height="240px"
    >
      <v-card-title class="text-h5" v-text="product.title" style="text-shadow: rgba(0,0,0,0.25) 2px 2px 2px;"></v-card-title>
      <v-card-subtitle class="text-h4 pb-0" v-text="product.price" style="text-shadow: rgba(0,0,0,0.25) 2px 2px 2px;"></v-card-subtitle>
      <v-card-subtitle v-if="product.promocode" class="text-h5 pb-0 pt-0" v-text="product.promocode" style="text-shadow: rgba(0,0,0,0.25) 2px 2px 2px;"></v-card-subtitle>    
    </v-img>
    <v-card-actions>
      <v-simple-checkbox 
        :ripple="false" 
        v-model="product.enabled" 
        :label="`Enabled`"
        @input="$emit('enable')">
      </v-simple-checkbox>    
      <v-spacer/>
      <v-btn icon :href="product.productUrl" target="_blank"><v-icon>mdi-link</v-icon></v-btn>
      <v-btn icon @click="$emit('edit')"><v-icon>mdi-pencil</v-icon></v-btn>
      <v-btn icon @click="$emit('delete')"><v-icon>mdi-delete</v-icon></v-btn>

    </v-card-actions>
  </v-card>

</v-flex>
</template>

<script>
  export default {
    name: 'ProductCard',
    props: {
      product: {
        type: Object
      }
    },
  }
</script>
