<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          fixed bottom right
          v-bind="attrs"
          v-on="on">
          <v-icon>mdi-message-text</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title> <span class="text-h5">Feedback</span> </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field 
                label="Contact email"
                v-model="email">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="text-center">
                  <v-rating
                    v-model="rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    empty-icon="$ratingFull"
                    half-increments
                    hover
                    large
                  ></v-rating>
                </div>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="feedback"
                  hide-details
                  label="Feedback message"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="clear"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            @click="save"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { getAuth } from 'firebase/auth';
  import { db } from "../firebase";
  import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

  export default {
    data: () => ({
      dialog: false,
      loading: false,
      rating: null,
      feedback: "",
      email: "",
      uid: ""
    }),
    mounted () {
      let user = getAuth().currentUser;
      if(user) {
        this.email = user.email;
        this.uid = user.uid;
      }
    },
    methods: {
      save() {
        this.loading = true;
        let created = serverTimestamp();
        let {rating, feedback, email, uid} = this;
        addDoc(collection(db,"feedbacks"), {
          rating, feedback, email, uid, created, 
          answered: false, sended: false
        })
        .then((res)=>console.log(res))
        .catch((err)=>console.error(err))
        .finally(()=>this.clear())
      },
      clear() {
        this.loading = false;
        this.rating = null;
        this.feedback = "";
        this.dialog = false;
      }
    }
  }
</script>