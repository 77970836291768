<template>

    <v-app-bar app color="primary" dark >
      <div class="d-flex align-center">
        <v-icon left large>mdi-message-video</v-icon>
        <h2 class="d-none d-sm-inline">streamfuel</h2>
      </div>
      <v-spacer></v-spacer>
      <v-chip v-if="user" outlined class="d-none d-sm-inline mr-4"><v-icon left>mdi-account</v-icon>{{user.email}}</v-chip>
      <v-btn v-if="user" @click="logout" outlined rounded text>logout</v-btn>
    </v-app-bar>

</template>

<script>
import { logout } from "../firebase"
import { getAuth, onAuthStateChanged } from "firebase/auth"

export default {
  name: 'AppBar',
  data: ()=>({
    user: null
  }),
  mounted() {
    // this.user = auth.currentUser;
    
    onAuthStateChanged(getAuth(), (user) => this.user=user);
  },
  methods: { 
   logout
  }
};
</script>
