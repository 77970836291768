<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      width="75%"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-img 
          :src="playerBackground"
          max-height="900">
          <iframe ref="iframe" :srcdoc="playerHtml"></iframe>
          <v-btn absolute right top icon dark @click="$emit('close')" > <v-icon>mdi-close</v-icon> </v-btn>
        </v-img>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

  import playerBackground  from "../assets/player-background.jpg";

  export default {
    name: 'ProductPlayerFullsreen',
    props: {
      enable: Boolean,
      playerHtml: String,
    },
    data: ()=>({
      show: false,
      playerBackground
    }),
    watch: {
      show(n) {if(!n) this.$emit("close");},
      enable(n) { this.show = n; }
    }
  }
</script>

<style scoped>
  iframe {
    border: none;
    width: 25%;
    height: 100%;
    overflow: show;
  }
</style>