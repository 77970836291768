
import {initializeApp} from 'firebase/app'
import { getFirestore, collection, doc, getDoc, getDocs, setDoc} from 'firebase/firestore';
// import { getAuth, signInWithEmailAndPassword, signOut} from 'firebase/auth';
import * as auth from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

// import { getAuth } from 'firebase/auth';
// import { onUnmounted } from 'vue';

// const firebaseConfig = {
//   apiKey: "AIzaSyCdmGmW8nnSrAGfxmez30pXy8JvTC-ojm8",
//   authDomain: "merch-ad.firebaseapp.com",
//   projectId: "merch-ad",
//   storageBucket: "merch-ad.appspot.com",
//   messagingSenderId: "336266675875",
//   appId: "1:336266675875:web:03bf37e23d6b957ccd2116",
//   measurementId: "G-RYDCWKC2QQ"
// };

const firebaseConfig = {
  apiKey: "AIzaSyDO7WgkjwZbr9ma0wtt58EOiEXlWHYax5w",
  authDomain: "streamfuel-live.firebaseapp.com",
  projectId: "streamfuel-live",
  storageBucket: "streamfuel-live.appspot.com",
  messagingSenderId: "851353573877",
  appId: "1:851353573877:web:716a3d9e58224b588e68bc",
  measurementId: "G-8B00QSD22M"
}

const app = initializeApp(firebaseConfig);
getAnalytics();

const db = getFirestore(app);
// db.settings({timestampInSnapshots: true});

function login(email, pass) {
  auth.signInWithEmailAndPassword(auth.getAuth(), email, pass)
  .then((user_cred) => {
    const user = user_cred.user;
    // console.log(user);
    let user_ref = doc(db,"users", user.uid); 
    getDoc(user_ref)
    .then((user_snp)=> {
      if(!user_snp.exists()) {
        console.log("Create user doc", user, user_snp);
        setDoc(user_ref, {id:user.uid});
      } else {
        user_snp;
      }
    })
    .then((user_snp)=> {
      console.log(user_snp);
      this.$router.push("/");
    })
  })
  .catch((err) => {
    console.error(err);
  });
}

function signin(email, pass) {
  auth.signInWithEmailAndPassword(auth.getAuth(), email, pass)
  .then((user_cred) => {
    const user = user_cred.user;
    // console.log(user);
    let user_ref = doc(db,"users", user.uid); 
    getDoc(user_ref)
    .then((user_snp)=> {
      if(!user_snp.exists()) {
        // console.log("Create user doc", user, user_snp);
        setDoc(user_ref, {id:user.uid});
      } else {
        user_snp;
      }
    })
    .then(()=> {
      // console.log(user_snp);
      this.$router.push("/");
    })
  })
  .catch((err) => {
    console.error(err);
  });
}

function logout() {
  return auth.signOut(auth.getAuth())
  .then( () => {
    console.log("Signed out");
    this.$router.push("/login");
  })
  .catch((err)=>console.error(err))
}

export const useUserProduct = () => {

}

export {
  login, signin, logout,
  db, collection, getDocs
}
